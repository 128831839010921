$(function () {
  $(document).on('click', 'a[data-remote-modal]', function (event) {
    event.preventDefault();
    Modal.showRemoteModal(this.href, this.dataset.title, this.dataset.modalClass, this.dataset.animateClass);
  });

  $(document).on('click', 'a[data-modal]', function (event) {
    event.preventDefault();
    var content = $('.' + this.dataset.modalClass).html()
    Modal.showModal(this.dataset.title, content);
  });

  $(document).on('confirm', function (event) {
    event.preventDefault();
    Modal.showCustomConfirm(event.target);
    return false;
  });

  $(document).on('submit', 'form[data-turbo-confirm]', function (event) {
    event.preventDefault();
    Modal.showCustomConfirm(event.target);
    return false;
  });
});

const Modal = {
  initOverlayHandler: function(){
    // marked location popup, edit link sends ajax request
    $('#popup a[data-remote]').not('a[data-confirm]').on('click', function (event) {
      event.preventDefault();
      if(this.dataset.method == 'put'){
        $.get(this.href, null, null, 'script');
        Map.closePopup();
        return false;
      }
    });

    $('#popup a[data-remote-modal]').on('click', function (event) {
      event.preventDefault();
      Modal.showRemoteModal(this.href, this.dataset.title, this.dataset.modalClass, this.dataset.animateClass);
    });

    $('#popup a[data-confirm]').on('click', function (event) {
      event.preventDefault();
      Modal.showCustomConfirm(this);
    });

    $('#popup .bookmark').on('click', function(event){
      event.preventDefault();
      MarkedDrive.showForm(this);
    });
  },

  showCustomConfirm: function(element){
    var text = $(element).data('confirm') || $(element).data('turbo-confirm');
    var form = $(element).is('form');
    var link = $(element).clone().removeClass().addClass('btn confirm-modal btn-primary').text('OK').removeAttr('data-confirm');

    var content = $('<div class="form-group">' +
      '<div class="text-center">' +
      '<p>' + text + '</p>' +
      '<button data-bs-dismiss="modal" class="btn btn-default cancel-request" type="button">Cancel</button>' +
      '</div>' +
      '</div>');
    content.find('.text-center').append(link);

    if (form) {
      link.click(function () {
        $(element).removeAttr('data-turbo-confirm')
        element.requestSubmit();
      });
    }

    if($('.custom-confirm').length){
      $('.confirm-dialog').remove();
      content.addClass('confirm-dialog modal-content');
      $(document.body).append(content);
      $('.confirm-dialog .btn').click(function(){
        content.remove();
      });
      return false;
    } else {
      Modal.showModal(null, content, 'modal-sm custom-confirm modal-confirm');
    }
  },

  showRemoteModal: function (url, title, modal_class, animate_class) {
    Modal.close();
    var header = '<div class="modal-header"><h5 class="modal-title">' + title + '</h5><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>';
    var modal_content = $('<div class="modal fade '+ animate_class +'"><div class="modal-dialog"><div class="modal-content">' + header + '<div class="modal-body clearfix"></div></div></div></div>');
    modal_content.modal('show');
    modal_content.find('.modal-body').load(url);
    if (modal_class) {
      modal_content.find('.modal-dialog').addClass(modal_class);
    }
    modal_content.on('hidden.bs.modal', function () {
      modal_content.remove();
    });
    modal_content.on('shown.bs.modal', function () {
      $('.modal-body form').prepend('<div class=\'error-container\'></div>');
    });
  },

  showModal: function(title, content, modal_class){
    if(!title){
      var modal_content = $('<div class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-body all-categories clearfix"></div></div></div></div>');
    } else {
      var header = '<div class="modal-header"><h5 class="modal-title">' + title + '</h5><button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button></div>';
      var modal_content = $('<div class="modal fade"><div class="modal-dialog"><div class="modal-content">' + header + '<div class="modal-body all-categories clearfix"></div></div></div></div>');
    }
    modal_content.find('.modal-body').html(content);
    if (modal_class) {
      modal_content.find('.modal-dialog').addClass(modal_class);
    }
    modal_content.modal('show');

    modal_content.on('hidden.bs.modal', function () {
      modal_content.remove();
    });
  },

  close: function () {
    $('.modal').modal('hide');
  }
};

export { Modal }

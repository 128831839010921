import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phaseSelect"]
  static values = {
    stage1: { type: Array, default: [] },
    stage2a: { type: Array, default: [] },
    stage2b: { type: Array, default: [] }
  }

  changePhaseFieldOptions(event) {
    const stageField = event.target
    const selectedOption = stageField.options[stageField.selectedIndex].text
    const selectedStage = selectedOption.toLowerCase().replace(/\s/g, '')

    this.populateOptions(this[`${selectedStage}Value`])
  }

  populateOptions(options) {
    this.phaseSelectTarget.innerHTML = ''

    options.forEach(option => {
      const opt = document.createElement('option');
      opt.value = option[1];
      opt.text = option[0];
      this.phaseSelectTarget.appendChild(opt);
    });
  }
}

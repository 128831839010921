import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['invitationModal', 'invitationModalBody', 'confirmBtn']

  handleInvitation(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.showCustomAlert(event)
  }

  showCustomAlert(event) {
    // Create overlay
    var overlay = document.createElement("div");
    overlay.classList.add("overlay");
    document.body.appendChild(overlay);

    this.invitationModalBodyTarget.innerHTML = event.currentTarget.dataset.body
    this.confirmBtnTarget.href = event.currentTarget.href
    this.invitationModalTarget.classList.remove('d-none')
  }

  closeAlert() {
    document.querySelector(".overlay").remove();
    this.invitationModalTarget.classList.add('d-none')
  }
}

import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";
import 'cleave.js/dist/addons/cleave-phone.au'

export default class extends Controller {
  static values = {
    phoneFormatting: { type: Boolean, default: false },
    customFormatting: { type: Boolean, default: false },
    countryCode: { type: String },
    mobilePrefix: { type: String },
    customBlock: { type: Array, default: [] },
    input: { type: String }
  }

  connect() {
    if (this.phoneFormattingValue) this.formatPhoneField()
    if (this.customFormattingValue) this.customField()

    if(this.inputValue) { this.cleave.setRawValue(this.inputValue) }
  }

  formatPhoneField() {
    this.cleave = new Cleave(this.element, {
      phone: true,
      phoneRegionCode: this.countryCodeValue
    })
  }

  customField() {
    this.cleave = new Cleave(this.element, {
      prefix: this.mobilePrefixValue,
      numericOnly: true,
      blocks: this.customBlockValue,
      noImmediatePrefix: true
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['withdrawalMemberForm', 'dateField', 'errorMessage']
  static values = {
    birthDate: String
  }

  connect () {
    $('#members-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "retrieve": true,
      "ajax": {
        "url": $('#members-datatable').data('source')
      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "id"},
        {"data": "full_name"},
        {"data": "email"},
        {"data": "mobile"},
        {"data": "stage"},
        {"data": "actions"},
      ],
      "drawCallback": function () {
        $('.dataTables_paginate > .pagination li').addClass('page-item');
        $('.dataTables_paginate > .pagination a').addClass('page-link');
      }
    });
  }

  validateDateOfWithdrawal(event) {
    const withdrawalDate = new Date(this.dateFieldTarget.value);
    const birthDate = new Date(this.birthDateValue);
    const errorMessage = this.errorMessageTarget;

    if (withdrawalDate < birthDate) {
      errorMessage.style.display = "block";
      this.dateFieldTarget.classList.add("is-invalid");
    } else {
      errorMessage.style.display = "none";
      this.dateFieldTarget.classList.remove("is-invalid");
    }
  }

  withdrawalMember(event) {
    if (this.dateFieldTarget.classList.contains("is-invalid")) {
      event.preventDefault();
    } else {
      this.withdrawalMemberFormTarget.requestSubmit();
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ["cogstateAssessmentForm", "deviceField", "placeField", "cogstateLoader"]
  static values = {
    surveyModel: Object,
    answers: Object,
    language: String
  }

  connect() {
    console.log("Assessment controller connected");
    this.element.classList.add("survey-container");
    if ( document.querySelector('#surveyRenderer') != null){
      this.initializeSurvey();
    }
  }

  initializeSurvey() {
    var model = new Survey.Model(this.surveyModelValue);
    model.data = this.answersValue;
    model.locale = this.languageValue;

    this.dispatchModelEvent(model)

    $("#surveyRenderer").Survey({
      model: model,
      "textUpdateMode": 'onTyping',
      onComplete: (survey) => {
        console.log("Survey results: " + JSON.stringify(survey.data));

        this.sendSurveyData(survey);
      }
    });
  }

  sendSurveyData(survey) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/members/assessments';
    form.dataset.turboFrame = 'assessment_section';

    const csrfToken = document.querySelector("[name='csrf-token']").content;

    form.appendChild(this.input('authenticity_token', csrfToken));
    form.appendChild(this.input('assessment_id', this.surveyModelValue.data.assessment_id));
    form.appendChild(this.input('status', 'completed'));

    Object.keys(survey.data).forEach(key => {
      form.appendChild(this.input(`answers[${key}]`, survey.data[key]));
    });

    document.body.appendChild(form);
    form.submit();
  }

  input(name, value) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  }

  dispatchModelEvent(model) {
    const event = new CustomEvent('surveyJsEdited', {
      detail: { model }
    });
    window.dispatchEvent(event);
  }

  loadPositionFields(event) {
    const selectedStages = Array.from(event.target.selectedOptions).map(option => option.value)

    setTimeout(() => {
      get(event.target.dataset.url, {
        contentType: "application/json",
        query: { stage_ids: JSON.stringify(selectedStages) },
        responseKind: "turbo-stream",
        traditional: true
      });
    }, 500)
  }

  cogstateAssessmentUrl(event) {
    const isDeviceField = event.currentTarget.name === 'device';
    const isPlaceField = event.currentTarget.name === 'place';

    this.toggleActiveState(isDeviceField, isPlaceField, event.currentTarget);

    const hasDeviceValue = this.hasCheckedValue(this.deviceFieldTargets);
    const hasPlaceValue = this.hasCheckedValue(this.placeFieldTargets);

    if (this.shouldSubmitForm(hasDeviceValue, hasPlaceValue)) {
      this.submitForm();
      this.cogstateLoaderTarget.classList.remove('d-none');
    }
  }

  toggleActiveState(isDeviceField, isPlaceField, currentTarget) {
    if (isDeviceField) {
      this.removeActiveState(this.deviceFieldTargets);
    }

    if (isPlaceField) {
      this.removeActiveState(this.placeFieldTargets);
    }

    currentTarget.parentElement.classList.add('label-active');
  }

  removeActiveState(targets) {
    targets.forEach(element => {
      element.parentElement.classList.remove('label-active');
    });
  }

  hasCheckedValue(fieldTargets) {
    return fieldTargets.some(field => field.checked);
  }

  shouldSubmitForm(hasDeviceValue, hasPlaceValue) {
    return this.cogstateAssessmentFormTarget.dataset.stage === 'stage_1' || (hasDeviceValue && hasPlaceValue);
  }

  submitForm() {
    this.cogstateAssessmentFormTarget.requestSubmit();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeParsley();
  }

  initializeParsley() {
    this.parsleyInstance = Parsley(this.element);

    this.parsleyInstance.on('field:validated', () => {
      const isValid = this.element.querySelectorAll('.parsley-error').length === 0;
      this.toggleCallout(isValid);
    });

    this.parsleyInstance.on('form:submit', () => {
      return false;
    });
  }

  toggleCallout(isValid) {
    const infoCallout = document.querySelector('.bs-callout-info');
    const warningCallout = document.querySelector('.bs-callout-warning');

    if (infoCallout && warningCallout) {
      infoCallout.classList.toggle('hidden', !isValid);
      warningCallout.classList.toggle('hidden', isValid);
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { event } from "jquery";

export default class extends Controller {
  static targets = [
    'consentBox',
    'registrationBtn',
    'customAlertBox',
    'leaveBtn',
    'healthCondition',
    'surveyNextBtn'
  ]

  static values = {
    savedChanges: Boolean
  }

  connect() {
    this.unsavedChanges = this.savedChangesValue;
    this.turnOnRegistrationBtn();
    this.handleUnsavedFormChanges();

    this.boundHandleUnload = this.handleUnload.bind(this);

    window.addEventListener('beforeunload', this.boundHandleUnload);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.boundHandleUnload);
  }

  turnOnRegistrationBtn() {
    if (!this.hasConsentBoxTarget) { return; }

    const allChecked = this.consentBoxTargets.every(checkbox => checkbox.checked);
    let enableButton = allChecked;

    const radioButtons = document.querySelectorAll("input[type='radio']");
    if (radioButtons.length > 0) {
      const anyRadioSelected = Array.from(radioButtons).some(radio => radio.checked);
      enableButton = enableButton && anyRadioSelected;
    }

    if (enableButton) {
      this.registrationBtnTarget.classList.remove('disabled');
    } else {
      this.registrationBtnTarget.classList.add('disabled');
    }
  }

  setChanges() {
    this.unsavedChanges = true;
  }

  resetChanges() {
    this.unsavedChanges = false;
  }

  handleUnload(event) {
    if (this.unsavedChanges) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  }

  handleTurboNavigation(event) {
    if (this.unsavedChanges) {
      event.preventDefault();
      this.customAlert(event)
    }
  }

  customAlert(event) {
    // Create overlay
    var overlay = document.createElement("div");
    overlay.classList.add("overlay");
    document.body.appendChild(overlay);

    this.customAlertBoxTarget.classList.remove('d-none')
    this.leaveBtnTarget.href = event.currentTarget.href
  }

  closeAlert(event) {
    document.querySelector(".overlay").remove();
    this.customAlertBoxTarget.classList.add('d-none')
  }

  checkHealthCondition(event) {
    const currentTarget = event.currentTarget;
    const form = this.surveyNextBtnTarget.closest('form');
    const isChecked = currentTarget.checked;
    const isNoOption = currentTarget.value === 'No';

    this.healthConditionTargets.forEach(element => {
      if (isChecked) {
        this.handleCheckedState(currentTarget, element, form, isNoOption);
      } else {
        this.handleUncheckedState(element);
      }
    });

    this.toggleNextButton(isChecked);
  }

  handleCheckedState(currentTarget, element, form, isNoOption) {
    if (isNoOption && currentTarget.id !== element.id) {
      element.setAttribute('disabled', true);
      element.checked = false;
    }

    form.querySelector('input[name="survey_end"]').value = isNoOption ? 'false' : 'true';
  }

  handleUncheckedState(element) {
    element.removeAttribute('disabled');
  }

  toggleNextButton(isEnabled) {
    this.surveyNextBtnTarget.classList.toggle('disabled', !isEnabled);
  }

  handleUnsavedFormChanges() {
    const form = document.getElementById("referral-form")

    if (form != null) {
      if (form.dataset.formErrors == 'true') {
        this.setChanges();
      }

      const formInputs = form.querySelectorAll("input, textarea, select");

      formInputs.forEach(input => {
        input.addEventListener('input', () => {
          this.setChanges();
        });
      });
    }
  }
}

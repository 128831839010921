import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ["customAlertBox", "leaveBtn", "customModalTitle", "customModalBody"]

  connect() {
    this.clickNextOrPreviousBtn = false
    window.isModalShown = false
    setTimeout(() => {
      this.addEventListenersOnSurveyForm();
    }, 0);

    this.handleTurboLinksNavigation()
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    window.addEventListener('surveyJsEdited', this.handleSurveyJsEdited);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.assessmentCompleted()
  }

  disconnect() {
    window.removeEventListener('surveyJsEdited', this.handleSurveyJsEdited);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.resetModalShown()
  }

  handleSurveyModelChanges(model) {
    model.onValueChanged.add(() => {
      this.setChanges();
    })

    model.onComplete.add(() => {
      this.resetChanges();
    })
  }

  addEventListenersOnSurveyForm() {
    const survey = document.getElementById("surveyRenderer")
    if (survey != null) {
      const surveyInputs = survey.querySelectorAll("input, textarea, select");

      surveyInputs.forEach(input => {
        input.addEventListener('input', () => {
          this.setChanges();
        });
      });
    }
  }

  handleBeforeUnload(event) {
    if((this.unsavedChanges || this.cogstateAssessment()) && !this.clickNextOrPreviousBtn){
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  }

  setChanges() {
    this.unsavedChanges = true
  }

  resetChanges() {
    this.unsavedChanges = false
  }

  handleTurboNavigation(event) {
    if (this.unsavedChanges || this.cogstateAssessment()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.showCustomAlert(event)
    }
  }

  handleSignOut(event) {
    this.handleTurboNavigation(event)
    this.leaveBtnTarget.dataset['method'] = event.currentTarget.dataset['method'];
    this.setLinkUrl(event.currentTarget.href)
  }

  handleTurboLinksNavigation() {
    window.addEventListener('turbo:before-visit', (event) => {
      const pathName = event.detail.url.replace(window.location.origin, '')
      const currentLink = document.querySelector(`a[href='${pathName}']`)
      const currentForm = document.querySelector(`form[action='${pathName}']`)

      if(window.isModalShown) {
        return;
      } else {
        if (currentForm || (currentLink && currentLink.innerHTML != 'Sign out')) {
          this.handleTurboNavigation(event)
           this.setAlertButtonUrl(currentForm, pathName)
        }
      }
    })
  }

  showCustomAlert() {
    // Create overlay
    var overlay = document.createElement("div");
    overlay.classList.add("overlay");
    document.body.appendChild(overlay);

    this.customAlertBoxTarget.classList.remove('d-none')
    this.setModalShown()
    this.confirmTextForCogstateAssessment()
  }

  closeAlert() {
    document.querySelector(".overlay").remove();
    this.customAlertBoxTarget.classList.add('d-none')
    this.resetModalShown()
  }

  setAlertButtonUrl(currentForm, pathName){
    if (currentForm != null){
      this.leaveBtnTarget.addEventListener('click', () => {
        currentForm.requestSubmit();
      })
     } else {
      this.setLinkUrl(pathName)
    }
  }

  setLinkUrl(url) {
    this.leaveBtnTarget.href = url
  }

  handleSurveyJsEdited = (event) => {
    const model = event.detail.model;
    this.handleSurveyModelChanges(model);
  }

  cogstateAssessment() {
    const cogstateIframe = document.querySelector('#cogstateIframe')
    return (cogstateIframe != null && cogstateIframe.dataset.cogstate == 'true')
  }

  confirmTextForCogstateAssessment() {
    if(this.cogstateAssessment()) {
      this.customModalTitleTarget.innerHTML = this.customModalTitleTarget.dataset.cogstateHeading
      this.customModalBodyTarget.innerHTML = this.customModalBodyTarget.dataset.cogstateBody
    }
  }

  setModalShown() {
    window.isModalShown = true
  }

  resetModalShown() {
    window.isModalShown = false
  }

  assessmentCompleted() {
    const cogstateIframe = document.querySelector('#cogstateIframe');

    if (cogstateIframe != null) {
      const sendAssessmentCompletedRequest = () => {
        $.ajax({
          url: cogstateIframe.dataset.assessmentCompletedUrl,
          method: 'PATCH',
          contentType: "application/json",
          dataType: "turbo-stream",
          traditional: true,
        });
      };

      document.addEventListener("DOMContentLoaded", sendAssessmentCompletedRequest);

      window.addEventListener("message", (event) => {
        if (event.data === "Screener_Success") {
          get("/members/assessments", {
            query: {
              assessment_id: cogstateIframe.dataset.assessmentId
            },
            responseKind: 'turbo-stream'
          }).then(() => {
            const targetUrl = `/members/assessments?assessment_id=${cogstateIframe.dataset.assessmentId}`;
            Turbo.visit(targetUrl, { action: "replace" });
          })
        }
      });
    }
  }

  clickAssessmentBtns() {
    this.clickNextOrPreviousBtn = true
  }
}

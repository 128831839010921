import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(document).ready(function() {
      $('#assessments-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "retrieve": true,
        "searching": false,
        "ajax": {
          "url": $('#assessments-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          { "data": "name" },
          { "data": "category" },
          { "data": "stages" },
          { "data": "stage_1_position" },
          { "data": "stage_2a_position" },
          { "data": "stage_2b_position" },
          { "data": "optional" },
          { "data": "actions" }
        ],
        "drawCallback": function () {
          $('.dataTables_paginate > .pagination li').addClass('page-item');
          $('.dataTables_paginate > .pagination a').addClass('page-link');
        }
      });
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["referralContactFields", "creeddStudyField", "raceddStudyField", "otherStudyFields", "archiveConfirmBtn",
                    "referralModalBody", "referralModalHeading", "contactPersonField", "enrolledStudyField", "consentForm",
                    "dateTimeField", "errorMessage"]
  static values = {
    birthDate: String
  }

  connect () {
    $('#referrals-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "retrieve": true,
      "ajax": {
        "url": $('#referrals-datatable').data('source')
      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "full_name"},
        {"data": "email"},
        {"data": "mobile"},
        {"data": "dob"},
        {"data": "gender"},
        {"data": "gp_name"},
        {"data": "submitted_date"},
        {"data": "actions"}
      ],
      "drawCallback": function () {
        $('.dataTables_paginate > .pagination li').addClass('page-item');
        $('.dataTables_paginate > .pagination a').addClass('page-link');
      }
    });

    this.enableRadioButtons();
  }

  toggleReferralContactFields(event) {
    this.toggleFieldVisibility(event.target.value, this.referralContactFieldsTarget, 'false');

    this.contactPersonFieldTargets.forEach(field => {
      this.setRequiredField(event.target.value, 'false', `#${field.id}`)
    })
  }

  toggleEnrolledOtherStudytFields(event) {
    this.toggleFieldVisibility(event.target.value, this.creeddStudyFieldTarget, 'creedd');
    this.toggleFieldVisibility(event.target.value, this.raceddStudyFieldTarget, 'racedd');
    this.toggleFieldVisibility(event.target.value, this.otherStudyFieldsTarget, 'other');

    this.enrolledStudyFieldTargets.forEach(field => {
      this.setRequiredField(event.target.value, field.dataset.condition, `#${field.id}`)
    })
  }

  toggleFieldVisibility(value, target, condition) {
    const shouldShow = value === condition;
    target.classList.toggle('d-none', !shouldShow);
  }

  setRequiredField(value, condition, selector) {
    if (value == condition) {
      document.querySelector(selector).setAttribute('required', true)
    } else {
      document.querySelector(selector).removeAttribute('required')
    }
  }

  validateInput(event) {
    const currentInput = event.currentTarget;
    const parentDiv = currentInput.parentElement.parentElement;
    const errorDiv = parentDiv.querySelector('#custom-errors');
    const inputs = parentDiv.querySelectorAll('input');
    const selects = parentDiv.querySelectorAll('select');
    const isValid = currentInput.value !== parentDiv.dataset.condition;

    inputs.forEach(element => {
      document.querySelector(`label[for=${element.id}]`).classList.remove('active');
    });

    document.querySelector(`label[for=${currentInput.id}]`).classList.add('active');

    if (errorDiv != null) {
      errorDiv.innerHTML = isValid ? '' : parentDiv.dataset.message;
    }

    this.addInvalidClass(selects, isValid)
    this.addInvalidClass(inputs, isValid)
  }

  addInvalidClass(inputCollection, isValid) {
    inputCollection.forEach(element => {
      element.classList.toggle('is-invalid', !isValid);
      element.parentElement.classList.toggle('has-error', !isValid);
    });
  }

  confirm(event) {
    const elementDataset = event.target.parentElement.dataset
    this.referralModalHeadingTarget.innerHTML = elementDataset.heading
    this.referralModalBodyTarget.innerHTML = elementDataset.message
    this.archiveConfirmBtnTarget.dataset.turboMethod = elementDataset.method
    this.archiveConfirmBtnTarget.href = elementDataset.url
  }

  enableRadioButtons() {
    const form = document.getElementById("referral-form");
    if (!form) return;

    const radioButtons = form.querySelectorAll("input[type='radio']");

    radioButtons.forEach(radio => {
      const label = form.querySelector(`label[for=${radio.id}]`);

      if (radio.classList.contains('is-invalid')) {
        radio.parentElement.classList.add('has-error');
      }

      if (radio.checked && label) {
        label.classList.add('active');
      }
    });
  }

  validateDate() {
    const ConsentDateTime = new Date(this.dateTimeFieldTarget.value);
    const birthDate = new Date(this.birthDateValue);
    const errorMessage = this.errorMessageTarget;

    if (ConsentDateTime < birthDate) {
      errorMessage.style.display = "block";
      this.dateTimeFieldTarget.classList.add("is-invalid");
    } else {
      errorMessage.style.display = "none";
      this.dateTimeFieldTarget.classList.remove("is-invalid");
    }
  }

  referralConsent(event) {
    if (this.dateTimeFieldTarget.classList.contains("is-invalid")) {
      event.preventDefault();
    } else {
      this.consentFormTarget.requestSubmit();
    }
  }
}

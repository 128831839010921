import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hbpIdField", "identityAsField", "profileSettingForm", "postalAddressStateField", "postalAddressPostcodeField",
                    "postalAddressField"]

  toggleHbpIdField(event){
    this.toggleFieldVisibility(event.target.value, this.hbpIdFieldTarget, 'yes');
    this.setRequiredField(event.target.value, 'yes', '#hbp-id')
  }

  togglePostalAddressFields(event) {
    this.toggleFieldVisibility(event.target.value, this.postalAddressStateFieldTarget, 'true');
    this.toggleFieldVisibility(event.target.value, this.postalAddressPostcodeFieldTarget, 'true');
    this.toggleFieldVisibility(event.target.value, this.postalAddressFieldTarget, 'true');
    this.setRequiredField(event.target.value, 'true', '#postal-address')
    this.setRequiredField(event.target.value, 'true', '#postal-state')
    this.setRequiredField(event.target.value, 'true', '#postal-postcode')
  }

  toggleIdentityAsField(event) {
    this.toggleFieldVisibility(event.target.value, this.identityAsFieldTarget, 'identity_not_listed');
    this.setRequiredField(event.target.value, 'identity_not_listed', '#identify-as')
  }

  toggleFieldVisibility(value, target, condition) {
    const shouldShow = value === condition;
    target.classList.toggle('d-none', !shouldShow);
  }

  setRequiredField(value, condition, selector) {
    if (value == condition) {
      document.querySelector(selector).setAttribute('required', true)
    } else {
      document.querySelector(selector).removeAttribute('required')
    }
  }

  submitForm(){
    this.profileSettingFormTarget.requestSubmit();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['reportFormField']

  connect() {
    $(document).ready(function() {
      $('#report-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "retrieve": true,
        "searching": true,
        "ajax": {
          "url": $('#report-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          { "data": "name" },
          { "data": "participant_share_date" },
          { "data": "referrer_share_date" },
          { "data": "uploaded_file" },
          { "data": "uploaded_at" },
          { "data": "risk_level" },
          { "data": "actions" }
        ],
        "drawCallback": function () {
          $('.dataTables_paginate > .pagination li').addClass('page-item');
          $('.dataTables_paginate > .pagination a').addClass('page-link');
        }
      });
    });

    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.addEventListenersOnReportForm()
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.resetChanges()
  }

  addEventListenersOnReportForm() {
    const links = document.querySelectorAll('a')

    this.reportFormFieldTargets.forEach(input => {
      input.addEventListener('input', () => {
        this.setChanges();

        links.forEach(link => {
          if (link.href != '' && link.href != 'javascript:void(0);'){
            link.setAttribute('data-confirm', 'You have unsaved changes. Do you really want to go back?')
          }
        })
      });
    });
  }

  handleBeforeUnload(event) {
    if(this.unsavedChanges && document.querySelector('.confirm-modal') == null) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  }

  setChanges() {
    this.unsavedChanges = true
  }

  resetChanges() {
    this.unsavedChanges = false
  }
}

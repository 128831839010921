import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["screenSizeAlert"]

  connect() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const smallScreenWidthThreshold = 768;
    if (Math.min(window.screen.width, window.screen.height) < smallScreenWidthThreshold && !sessionStorage.getItem("screenSizeAlertClosed")) {
      this.customAlert();
    }
  };

  customAlert() {
    var overlay = document.createElement("div");
    overlay.classList.add("overlay");
    document.body.appendChild(overlay);

    this.screenSizeAlertTarget.classList.remove('d-none')
  }

  closeAlert() {
    document.querySelector(".overlay").remove();
    this.screenSizeAlertTarget.classList.add('d-none')

    sessionStorage.setItem("screenSizeAlertClosed", "true");
  }

  clearSession() {
    sessionStorage.removeItem("screenSizeAlertClosed")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["userTabLink"]

  connect () {
    $('#raters-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "retrieve": true,
      "ajax": {
        "url": $('#raters-datatable').data('source')
      },
      "pagingType": "full_numbers",
      "columns": [
        {"data": "full_name"},
        {"data": "email"},
        {"data": "mobile"},
        {"data": "account_status"},
        {"data": "current_sign_in_at"},
        {"data": "actions"}
      ],
      "drawCallback": function () {
        $('.dataTables_paginate > .pagination li').addClass('page-item');
        $('.dataTables_paginate > .pagination a').addClass('page-link');
      }
    });
  }

  toggleActiveClass(event){
    this.userTabLinkTargets.forEach(element => {
      element.classList.remove('active')
    });

    event.currentTarget.classList.add('active')
  }
}

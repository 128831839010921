import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['languageForm', 'languageField', 'headerLinks']

  connect() {
    this.changeFlag();
    this.toggleActiveClass();
  }

  changeFlag() {
    const selectLanguageField = this.languageFieldTarget
    const selectedOption = selectLanguageField.options[selectLanguageField.selectedIndex]
    const flagCode = selectedOption.getAttribute('data-flag');
    const currentFlag = Array.from(selectLanguageField.classList).find(className => className.startsWith('flag-icon-'))

    selectLanguageField.classList.remove(currentFlag)
    selectLanguageField.classList.add(flagCode)
  }

  submitForm() {
    this.languageFormTarget.requestSubmit();
  }

  toggleActiveClass(){
    this.headerLinksTargets.forEach(element => {
      if (element.href == window.location.href) {
        element.classList.add('active')
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const isSurveyjs = $('#assessment-members-datatable').data('assessmentType') == 'surveyjs'

    $(document).ready(function() {
      $('#assessment-members-datatable').dataTable({
        "processing": true,
        "serverSide": true,
        "retrieve": true,
        "searching": false,
        "ajax": {
          "url": $('#assessment-members-datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
          { "data": "name" },
          { "data": "completed" },
          { "data": "completed_at" },
          { "data": "skipped", "visible": isSurveyjs },
          { "data": "score", "visible": isSurveyjs },
          { "data": "actions", "visible": isSurveyjs }
        ],
        "drawCallback": function () {
          $('.dataTables_paginate > .pagination li').addClass('page-item');
          $('.dataTables_paginate > .pagination a').addClass('page-link');
        }
      });
    });
  }
}

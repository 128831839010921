import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const element = document.getElementById('onboarding');
    if (element) {
      element.scrollIntoView();
    }
  }
}
